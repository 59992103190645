
import React from 'react';
import NavBar from '../../components/navbar/navbar';
import BottomBar from '../../components/bottom-bar/bottomBar';
import LandingHeader from './landing-header/LandingHeader';
import AboutUs from './about-us/aboutUs';
import Action from './action/Action';
import LandingVideo from '../../components/landing-video/landingVideo';
import LatestArticles from './latest-articles/latestArticles';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './landing.css';

import foundersHubImage from '../../assets/images/foundersHub_image.jpeg'

export default function Landing() {

    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <div className='landingPage'>
            <NavBar />
            <LandingVideo />
            <LandingHeader />
            <Action />
            {currentLanguage !== 'en' &&
                <LatestArticles />}
            <div className="downloadContainer">
                <div className="downloadrow">
                    <Link className="downloadcolumn" to='https://itucekirdek.com/' target="_blank" >
                        <img className='downloadImg' src="https://img1.wsimg.com/isteam/ip/7890b365-6a9d-4d1a-a384-18a6952c788c/%C4%B0T%C3%9C%20%C3%87EK%C4%B0RDEK%20%C3%96D%C3%9CL%20LOGOLARI%202023_%C3%B6n%20kulucka.jpg/:/cr=t:0%25,l:13.36%25,w:73.28%25,h:100%25/rs=w:365,h:365,cg:true" alt="Network 2" />
                    </Link>
                    <Link className="downloadcolumn" to='https://foundershub.startups.microsoft.com/signup' target="_blank" >
                        <img className='downloadImg' src={foundersHubImage} alt="Network 2" />
                    </Link>
                </div>
            </div>
            <AboutUs />
            <BottomBar />
        </div>


    );
}


