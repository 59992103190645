import React from 'react';
import './aboutUs.css';
import LineHeader from '../../../components/line-header/LineHeader';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
function AboutUs() {
    const { t } = useTranslation();

    return (
        <div className="AboutUs">
            <LineHeader>{t('aboutUs')}</LineHeader>
            <div className="AboutUsItems">
                <Link className="AboutItem" to="/community">
                    <p>{t('yeterlyCommunity')}</p>
                    {t('joinUs')}
                </Link>
                <Link className="AboutItem" to="/our-story">
                    <p>{t('ourStory')}</p>
                    {t('learnMore')}
                </Link>
                <Link className="AboutItem" to="/our-team">
                    <p>{t('ourTeam')}</p>
                    {t('meetOurTeam')}
                </Link>
                <Link className="AboutItem" to="/privacy-security">
                    <p>{t('company')}</p>
                    {t('privacySecurity')}
                </Link>
            </div>
        </div>
    );
}

export default AboutUs;