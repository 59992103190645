import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';

function InlineMarkdown({ url, charLimit }) {
    const [markdownContent, setMarkdownContent] = useState('');

    useEffect(() => {
        const fetchMarkdown = async () => {
            try {
                const response = await fetch(url);
                const text = await response.text();
                if (charLimit === undefined) {
                    setMarkdownContent(text);
                } else {
                    setMarkdownContent(text.substring(0, charLimit) + '...');
                }
            }
            catch (error) {
                console.error('Error fetching markdown:', error);
            }
        }
        fetchMarkdown();
    }, [url, charLimit]);
    return (
        <Markdown className="InlineMarkdown">{markdownContent}</Markdown>
    );
}

export default InlineMarkdown;