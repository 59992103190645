import React from 'react';
import './etk.css';
import LineHeader from '../../../components/line-header/LineHeader';
import NavBar from '../../../components/navbar/navbar';
import BottomBar from '../../../components/bottom-bar/bottomBar';

import { useTranslation } from 'react-i18next';

function Etk() {
    const { t } = useTranslation();
    return (
        <>
            <NavBar />
            <LineHeader>{t('etkTitle')}</LineHeader>
                <p className='etkContent'>{t('etkDesc')}</p>
                
            
            <BottomBar />


        </>
    );
}

export default Etk;