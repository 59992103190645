import React from 'react';
import './landingVideo.css';
import { useTranslation } from 'react-i18next';
import CustomButton from '../custom-button/CustomButton';
import LandingVideoFile from '../../assets/videos/landingVideo.mp4';

function LandingVideo() {
  const { t } = useTranslation();
  return (
    <div className='videoWrapper'>
      <video autoPlay muted loop className="landingPageVideo">
        <source src={LandingVideoFile} type="video/mp4" />
      </video>
    </div>
  );
}

export default LandingVideo;