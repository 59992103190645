import React from 'react';
import { Link } from 'react-router-dom';
import './customButton.css';

const CustomButton = ({ label, reverse, to, url }) => {
  const buttonClass = reverse ? 'reverse-button' : 'my-button';

  if (to) {
    return (
      <Link to={to} className={buttonClass}>
        {label}
      </Link>
    );
  }

  if (url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer" className={buttonClass}>
        {label}
      </a>
    );
  }

  return null;
};

export default CustomButton;