import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/navbar/navbar';
import './fullScreenBlog.css';
import Markdown from 'markdown-to-jsx';
import { useEffect, useState } from "react"
import {storage} from '../../firebase-config';
import { ref, getDownloadURL } from "firebase/storage";



function FullScreenBlog() {

    const [postContent, setPostContent] = useState("");
    const { id } = useParams();

    useEffect(() => {
        const fetchBlog = async () => {
          try {
            const storageRef = ref(storage, `blogs/${id}/blog.md`);
            await getDownloadURL(storageRef).then((url) => {
              console.log(url);
              fetch(url)
                .then((res) => res.text())
                .then((md) => {
                  setPostContent(md);
                });
            });
          } catch (error) {
            console.error("Error fetching blog:", error);
          }
        };  
        fetchBlog();
      }, [id]);
    

      
    return (
        <><Navbar /><div className="fullBlogPost">
            <Markdown >
              
            {postContent}
          </Markdown>
            

        </div></>
    );
}

export default FullScreenBlog;