import React from 'react';
import './HiringPage.css';
import LineHeader from '../../../components/line-header/LineHeader';
import NavBar from '../../../components/navbar/navbar';
import BottomBar from '../../../components/bottom-bar/bottomBar';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

function HiringPage() {
    const { t } = useTranslation();
    return (
        <div className='Hiring'>
            <NavBar />
            <div className="hiringPageHeader">
                <LineHeader>{t('hiring.title')}</LineHeader>
                <div className="ProgramContainer">
                    <Link className="Program" to="/hiring/content-creator" >
                        <h2>{t('hiring.contentCreator.title')}</h2>
                        <p>{t('hiring.contentCreator.description')}</p>
                    </Link>
                    <Link className="Program" to="/hiring/content-writer">
                        <h2>{t('hiring.contentWriter.title')}</h2>
                        <p>{t('hiring.contentWriter.description')}</p>
                    </Link>
                    <Link className="Program" to="/hiring/public-relations">
                        <h2>{t('hiring.publicRelations.title')}</h2>
                        <p>{t('hiring.publicRelations.description')}</p>
                    </Link>
                </div>
            <div className="hiringSpan"/>
            </div>
            <BottomBar />
        </div>
    );
}

export default HiringPage;