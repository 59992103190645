import './navbar.css';
import { Link } from "react-router-dom";
import logoSvg from '../../assets/images/navbar_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';


import { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import LanguageChangeSheet from '../language-sheet/languageSheet';

import Dropdown from './dropdown';

import { useTranslation } from 'react-i18next';

export default function Navbar() {

  const { t, i18n } = useTranslation();

  const [isExpended, setIsExpended] = useState(false);

  const currentLanguage = i18n.language;

  const toggleNavMenu = () => {
    setIsExpended(!isExpended);
  }

  const [isDropDownExpended, setIsDropDownExpended] = useState(false);

  const handleDropdownClick = () => {
    setIsDropDownExpended(!isDropDownExpended);
  };

  const navMenu = (
    <ul className='nav-menu'>
      <li className='nav-item'>
        <Link to='/home' className='nav-links' onClick={toggleNavMenu}>
          {t('home')}
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/accessibility-solutions' className='nav-links' onClick={toggleNavMenu}>
          {t('benefits')}
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/community' className='nav-links' onClick={toggleNavMenu}>
          {t('findPeopleAlikeYou')}
        </Link>
      </li>
      <li className='nav-item'>
        <Link className='nav-links' onClick={handleDropdownClick} >
          {t('more')} <IoIosArrowDown className={isDropDownExpended ? 'dropdownIcon rotate' : 'dropdownIcon'} />
        </Link>
        {isDropDownExpended && <Dropdown />}
      </li>
    </ul>
  );

  const navMenuExtended = (
    <ul className='nav-menu'>
      <li className='nav-item'>
        <Link to='/home' className='nav-links' onClick={toggleNavMenu}>
          {t('home')}
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/accessibility-solutions' className='nav-links' onClick={toggleNavMenu}>
          {t('benefits')}
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='community' className='nav-links' onClick={toggleNavMenu}>
          {t('findPeopleAlikeYou')}
        </Link>
      </li>
      {currentLanguage === 'tr' && <li className='nav-item'>
        <Link to='/blog' className='nav-links' onClick={toggleNavMenu}>
          {t('blog')}
        </Link>
      </li>}
      <li className='nav-item'>
        <Link to='/our-story' className='nav-links' onClick={toggleNavMenu}>
        {t('ourStory')}
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/our-team' className='nav-links' onClick={toggleNavMenu}>
        {t('ourTeam')}
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/hiring' className='nav-links' onClick={toggleNavMenu}>
          {t('internshipOpportunities')}
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/privacy-security' className='nav-links' onClick={toggleNavMenu}>
        {t('privacySecurity')}
        </Link>
      </li>
      <li className='nav-item nav-item-horizontal'>
        <Link to='https://app.yeterly.com/' className='nav-links' target="_blank" onClick={toggleNavMenu}>
          {t('webDemo')}
        </Link>
        <Link to='https://play.google.com/store/apps/details?id=com.yeterly.yeterly' className='nav-links' target="_blank" onClick={toggleNavMenu}>
          {t('downloadForAndroid')}
        </Link>
        <Link to='/https://apps.apple.com/tr/app/yeterly/id1667784286' className='nav-links' target="_blank" onClick={toggleNavMenu}>
          {t('downloadForIOS')}
        </Link>
      </li>
    </ul>
  );

  const navMenuWide = (
    <nav className='navbarWide'>
      <div className="topLeft">
        <Link to='/home' className='navbar-logo' >
          <img className="logo" src={logoSvg} alt="logo" />
        </Link>
      </div>
      <div className="center">
        {navMenu}
      </div>
      <div className="topRight">
        <LanguageChangeSheet />
      </div>
    </nav>
  );

  const navMenuTight = (
    <nav className='navbarTight' style={{ backgroundColor: isExpended ? '#7a10aa' : '#fff', marginBottom: isExpended ? '40px' : '0' }} >
      <div className='up'>
        <div className="topLeft">
          <div className='menu-toggle' onClick={toggleNavMenu}>
            <FontAwesomeIcon icon={isExpended ? faTimes : faBars} style={{ color: isExpended ? '#fff' : '#000' }} />
          </div>
        </div>
        <div className="center">
          <Link to='/home' className='navbar-logo' >
            <img className="logo" src={logoSvg} alt="logo" style={{ width: isExpended ? '180px' : '100px' }} />
          </Link>
        </div>
        <div className="topRight" style={{ color: isExpended ? '#fff' : '#7a10aa' }}>
          <LanguageChangeSheet />
        </div>
      </div>
      <div className='down'>
        {isExpended && navMenuExtended}
      </div>
    </nav>
  );

  return (
    <div className='navbar'>
      {navMenuWide}
      {navMenuTight}
    </div>
  );
}