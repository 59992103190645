import React from 'react';
import './languageSheet.css';
import { useTranslation } from 'react-i18next';

function LanguageChangeSheet() {
  const { i18n } = useTranslation();

  const handleChangeLanguage = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === 'tr' ? 'en' : 'tr';
    i18n.changeLanguage(newLanguage);
  };

  const currentLanguage = i18n.language;

  return (
    <div className="languageChangeSheet" onClick={handleChangeLanguage} >
      {currentLanguage === 'tr' ? 'EN' : 'TR'}
    </div>
  );
}

export default LanguageChangeSheet;