import React from "react";
import NavBar from '../../components/navbar/navbar';
import BottomBar from '../../components/bottom-bar/bottomBar';
import LineHeader from '../../components/line-header/LineHeader';
import { Link } from 'react-router-dom';

import "./ourTeam.css"

import { useTranslation } from 'react-i18next';

export default function OurTeam() {
    const { t } = useTranslation();

    function getImageUrl(imageName) {
        return `https://s3.eu-central-1.amazonaws.com/yeterly.com/images/team/${imageName}.jpg`
    }

    return (
        <div className="OurTeam">
            <NavBar />
            <LineHeader>{t('team.title')}</LineHeader>
            <div className="teamContainer">
                <Link className="Member" to="https://www.linkedin.com/in/muratcancicek/" target="_blank">
                    <img src={getImageUrl("muratcan")} alt={t('team.imgAlt')} />
                    <h1>Muratcan Çiçek</h1>
                    <p>{t('team.founder')} & CEO</p>
                </Link>
                <Link className="Member" to="https://www.linkedin.com/in/alkanmta/" target="_blank">
                    <img src={getImageUrl("talha")} alt={t('team.imgAlt')} />
                    <h1>M. Talha Alkan</h1>
                    <p>{t('team.founder')} & CTO</p>
                </Link>
                <Link className="Member" to="https://www.linkedin.com/in/mutlumustaffa/" target="_blank">
                    <img src={getImageUrl("mustafa")} alt={t('team.imgAlt')} />
                    <h1>Mustafa Mutlu</h1>
                    <p>{t('team.founder')} & COO</p>
                </Link>
                <Link className="Member" to="https://www.linkedin.com/in/bahar-filiz-7b172a141/" target="_blank">
                    <img src={getImageUrl("bahar")} alt={t('team.imgAlt')} />
                    <h1>Bahar Filiz</h1>
                    <p>{t('team.communityManager')}</p>
                </Link>
                <Link className="Member" to="https://www.linkedin.com/in/celikomerfaruk/" target="_blank">
                    <img src={getImageUrl("omer")} alt={t('team.imgAlt')} />
                    <h1>Ömer Faruk Çelik</h1>
                    <p>{t('team.jrSoftwareDeveloper')}</p>
                </Link>
                <Link className="Member" to="https://www.linkedin.com/in/isaadahanunal/" target="_blank">
                    <img src={getImageUrl("adahan")} alt={t('team.imgAlt')} />
                    <h1>İsa Adahan Ünal</h1>
                    <p>{t('team.jrSoftwareDeveloperIntern')}</p>
                </Link>
                <Link className="Member" to="https://www.linkedin.com/" target="_blank">
                    <img src={getImageUrl("eda")} alt={t('team.imgAlt')} />
                    <h1>Eda Başar</h1>
                    <p>{t('team.contentWriter')}</p>
                </Link>
                <Link className="Member" to="https://www.linkedin.com/in/eliz-canyurt-578039238/" target="_blank">
                    <img src={getImageUrl("eliz")} alt={t('team.imgAlt')} />
                    <h1>Eliz Canyurt</h1>
                    <p>{t('team.contentCreator')}</p>
                </Link>
                <Link className="Member" to="https://www.linkedin.com/in/elifcerengursun/" target="_blank">
                    <img src={getImageUrl("ceren")} alt={t('team.imgAlt')} />
                    <h1>Elif Ceren Gürşün</h1>
                    <p>{t('team.contentCreator')}</p>
                </Link>
                <Link className="Member" to="https://www.linkedin.com/in/selay-salih-019870231/" target="_blank">
                    <img src={getImageUrl("selay")} alt={t('team.imgAlt')} />
                    <h1>Selay Salih</h1>
                    <p>{t('team.publicRelations')}</p>
                </Link>
                <Link className="Member" to="https://www.linkedin.com/in/kübratatlıalma/" target="_blank">
                    <img src={getImageUrl("kubra")} alt={t('team.imgAlt')} />
                    <h1>Kübra Tatlıalma</h1>
                    <p>{t('team.publicRelations')}</p>
                </Link>
                <Link className="Member" to="https://www.linkedin.com/in/gizem-ari/" target="_blank">
                    <img src={getImageUrl("gizem")} alt={t('team.imgAlt')} />
                    <h1>Gizem Arı</h1>
                    <p>{t('team.publicRelations')}</p>
                </Link>
            </div>
            <BottomBar />
        </div>)
}