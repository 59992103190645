import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { firestore } from "../../../firebase-config";
import Navbar from "../../../components/navbar/navbar";
import BottomBar from "../../../components/bottom-bar/bottomBar";
import LineHeader from "../../../components/line-header/LineHeader";
import "./accessibilitySolutionsCategory.css";
import logoSvg from "../../../assets/images/logo192.png";

function AccessibilityToolsCategory() {
    const [subCategories, setSubCategories] = useState([]);
    const [tools, setTools] = useState({});
    const { t, i18n } = useTranslation();
    const { category } = useParams();

    const currentLanguage = i18n.language;

    useEffect(() => {
        const fetchtools = async () => {
            try {
                const docSnapshot = await getDoc(
                    doc(firestore, `solutions/${category}`)
                );
                const sCategories = docSnapshot.data().sub_category_list;
                setSubCategories(sCategories);
                for (let i = 0; i < sCategories.length; i++) {
                    const querySnapshot = await getDocs(
                        collection(
                            firestore,
                            `solutions/${category}/${sCategories[i]}`
                        )
                    );
                    const toolList = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setTools((prev) => ({
                        ...prev,
                        [sCategories[i]]: toolList,
                    }));
                }
            } catch (error) {
                console.error("Error fetching tools:", error);
            }
        };
        fetchtools();
    }, [category]);
    function solutionContent(item) {
        return (
            <div className="solution-details">
                {typeof item.link == "string" ? (
                    <div className="solution-icon">
                        <img
                            className="solution-image"
                            src={
                                "https://s2.googleusercontent.com/s2/favicons?domain_url=" +
                                item.link +
                                "&sz=256"
                            }
                            alt={item.name}
                        ></img>
                    </div>
                ) : (
                    <div className="solution-icon">
                        <img
                            className="solution-image"
                            src={logoSvg}
                            alt="Yeterly"
                        ></img>
                    </div>
                )}
                <div className="solution-name">{item.name}</div>
                <div className="solution-type">{item.type}</div>
                <div className="solution-description">
                    {currentLanguage === "tr"
                        ? item.description_tr
                        : item.description_en}
                </div>
            </div>
        );
    }
    function solutionTile(item) {
        if (item === undefined) return <div></div>;
        const link = typeof item.link == "string" ? item.link : "#";
        const target = typeof item.link == "string" ? "_blank" : "_self";
        if (link === "#") {
            return (
                <div className="solution-card" key={item.id}>
                    {solutionContent(item)}
                </div>
            );
        } else {
            return (
                <Link
                    className="solution-card"
                    key={item.id}
                    to={link}
                    target={target}
                >
                    {solutionContent(item)}
                </Link>
            );
        }
    }

    return (
        <div id={category} className="solutions-category">
            <Navbar />
            <LineHeader className="line-header">
                {t(`solutions.mainCategory.${category}`)}
            </LineHeader>

            <div className="solutions-category-container">
                {subCategories.map((subCategory) => (
                    <div key={subCategory} className="solutions-subcategory">
                        <div>
                            <LineHeader className="line-sub-header">
                                {t(`solutions.mainCategory.${subCategory}`)}
                            </LineHeader>
                        </div>

                        <div className="solutions-subcategory-container">
                            {subCategory in tools &&
                                tools[subCategory].map((item) =>
                                    solutionTile(item)
                                )}
                        </div>
                    </div>
                ))}
            </div>
            <BottomBar />
        </div>
    );
}

export default AccessibilityToolsCategory;
