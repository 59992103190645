import React from 'react';
import './consent.css';
import LineHeader from '../../../components/line-header/LineHeader';
import NavBar from '../../../components/navbar/navbar';
import BottomBar from '../../../components/bottom-bar/bottomBar';

import enContent from './en-content.md';
import trContent from './tr-content.md';
import { useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';

import { useTranslation } from 'react-i18next';

function Consent() {
    const { t, i18n } = useTranslation();
    const [mdContent, setMdContent] = useState('');

    useEffect(() => {
        const fetchMarkdownContent = async () => {
            try {
                let response;
                if (i18n.language === 'tr') {
                    response = await fetch(trContent);
                } else {
                    response = await fetch(enContent);
                }
                const md = await response.text();
                setMdContent(md);
            } catch (error) {
                console.error('Error fetching Markdown content:', error);
            }
        };

        fetchMarkdownContent();
    }, [i18n.language]);
    return (
        <>
            <NavBar />
            <LineHeader>{t('consentTitle')}</LineHeader>
            <div className="consentContent">
                <Markdown>

                    {mdContent}
                </Markdown>
                
            </div>
            <BottomBar />


        </>
    );
}

export default Consent;