import React, { useState, useEffect } from 'react';
import NavBar from '../../../components/navbar/navbar';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LineHeader from '../../../components/line-header/LineHeader';
import Markdown from 'markdown-to-jsx';
import ContentCreator from './ContentCreator.md';
import ContentWriter from './ContentWriter.md';
import PublicRelations from './PublicRelations.md';
import ApplyForRoleForm from '../apply-for-role-form/applyForRoleForm';
import BottomBar from '../../../components/bottom-bar/bottomBar';
import './InternRole.css';

function InternRole() {
    const { t } = useTranslation();
    const { role } = useParams();
    const [mdContent, setMdContent] = useState('');

    useEffect(() => {
        let content;
        if (role === 'content-creator') {
            content = ContentCreator;
        } else if (role === 'content-writer') {
            content = ContentWriter;
        } else if (role === 'public-relations') {
            content = PublicRelations;
        } else {
            setMdContent('empty');
            return;
        }
        fetch(content)
            .then((res) => res.text())
            .then((md) => {
                setMdContent(md);
            });
    }, [role]);

    const internRoleContainer = (
            <div className='InternRoleContainer'>
                <LineHeader>{t(`hiring.${role}.role`)}</LineHeader>
                    <Markdown className='InternRoleContent'>
                        {mdContent}
                    </Markdown>
                <LineHeader>{t('hiring.form.title')}</LineHeader>
                <ApplyForRoleForm className='InternForm' role={role} />
            </div>
    );
    const emptyInternRoleContainer = (
            <div className='InternRoleContainer'>
            </div>
    );

    return (
        <div className='InternRole'>
            <NavBar />
            {mdContent === 'empty' ? emptyInternRoleContainer : internRoleContainer}
            <BottomBar />
        </div>
    );
}

export default InternRole;