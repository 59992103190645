import React from 'react';
import './agreement.css';
import LineHeader from '../../../components/line-header/LineHeader';
import NavBar from '../../../components/navbar/navbar';
import BottomBar from '../../../components/bottom-bar/bottomBar';
import enAgreementContent from './en-agreement.md';
import trAgreementContent from './tr-agreement.md';
import enTermsOfUse from './en-termsofuse.md';
import trTermsOfUse from './tr-termsofuse.md';
import { useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';

import { useTranslation } from 'react-i18next';

function Agreement() {
    const { t, i18n } = useTranslation();
    const [agreementContent, setAgreementContent] = useState('');
    const [termsContent, setTermsContent] = useState('');



    useEffect(() => {
        const fetchMarkdownContent = async () => {
            try {
                let response;
                if (i18n.language === 'tr') {
                    response = await fetch(trAgreementContent);
                } else {
                    response = await fetch(enAgreementContent);
                }
                const md = await response.text();
                setAgreementContent(md);
            } catch (error) {
                console.error('Error fetching Markdown content:', error);
            }
        };

        fetchMarkdownContent();
    }, [i18n.language]);

    useEffect(() => {
        const fetchMarkdownContent = async () => {
            try {
                let response;
                if (i18n.language === 'tr') {
                    response = await fetch(trTermsOfUse);
                } else {
                    response = await fetch(enTermsOfUse);
                }
                const md = await response.text();
                setTermsContent(md);
            } catch (error) {
                console.error('Error fetching Markdown content:', error);
            }
        };
        
        fetchMarkdownContent();
    }, [i18n.language]);


    return (
        <>
            <NavBar />
            <LineHeader >{t('agreementTitle')}</LineHeader>
            <div className="consentContent">
                <Markdown>

                    {agreementContent}
                </Markdown>
                
            </div>
            <LineHeader>{t('termsOfUse')}</LineHeader>
            <div className="consentContent">
                <Markdown>

                    {termsContent}
                </Markdown>
                
            </div>
            <BottomBar />


        </>
    );
}

export default Agreement;