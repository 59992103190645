import React from 'react';
import LineHeader from '../../../components/line-header/LineHeader';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './LandingHeader.css';

function LandingHeader() {
  const { t } = useTranslation();
  return (
    <div className='LandingHeader'>
      <LineHeader className='LineHeader'>{t('landing.header.title')}</LineHeader>
      <div className="ContentContainer">
        <div className="Content">
          <Link 
            className="ContentLink"
            to='https://www.who.int/news-room/fact-sheets/detail/assistive-technology' 
            target='_blank'
          >
            <h3>{t('landing.header.subtitle-1')}</h3>
            <div className='ContentText'>
              <p>{t('landing.header.content-1')}</p>
              </div>
          </Link>
        </div>
        <div className="Content">
          <Link 
            className="ContentLink" 
            to='https://www.unicef.org/innovation/assistive-products-and-inclusive-supplies' 
            target='_blank'
          >
            <h3>{t('landing.header.subtitle-2')}</h3>
            <div className='ContentText'>
              <p>{t('landing.header.content-2')}</p>
            </div>
          </Link>
        </div>
        <div className="Content">
          <Link
           className="ContentLink" 
           to='/our-story' 
          >
            <h3>{t('landing.header.subtitle-3')}</h3>
            <div className='ContentText'>
              <p>{t('landing.header.content-3')}</p>
            </div>

          </Link>
        </div>
      </div>
    </div >
  );
}

export default LandingHeader;