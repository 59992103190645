import React from 'react';
import './Action.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
function Action() {
    const { t } = useTranslation();

    return (
        <div className="Action">
            <div className="ActionItems">
                <Link className="ActionItem" to="https://join.yeterly.com/signUp">
                    <p>{t('landing.action.join')}</p>
                </Link>
                <Link className="ActionItem" to="/Cases">
                    <p>{t('landing.action.experience')}</p>
                </Link>
                <Link className="ActionItem" to="/accessibility-solutions">
                    <p>{t('landing.action.solutions')}</p>
                </Link>
            </div>
        </div>
    );
}

export default Action;