import React, { useState } from 'react';
import './applyForRoleForm.css';
import { MdAttachFile } from 'react-icons/md';
import { sendEmail } from '../../../api/sendEmail';
import { useTranslation } from 'react-i18next';


const ApplyForRoleForm = ( {role} ) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [resume, setResume] = useState(null);
  const [motivation, setMotivation] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [attachmentCount, setAttachmentCount] = useState(0);

  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let roleTitle = '';
      if (role === 'content-creator') {
        roleTitle = 'Content Creator';
      } else if (role === 'content-writer') {
        roleTitle = 'Content Writer';
      } else if (role === 'public-relations') {
        roleTitle = 'Public Relations';
      }
      await sendEmail(email, name, motivation, linkedin, resume, roleTitle);
      // Show success message and clear form fields
      alert('Application submitted successfully!');
      setName('');
      setEmail('');
      setResume(null);
      setMotivation('');
      setLinkedin('');
      setAttachmentCount(0);
    } catch (error) {
      // Show error message
      alert('An error occurred while submitting the application. Please try again.');
      console.error(error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setResume(file);
      setAttachmentCount(attachmentCount + 1);
    } else {
      setResume(null);
      setAttachmentCount(0);
    }
  };

  return (
    <div className="form-container">
      <h1>{t('hiring.form.subtitle')}</h1>
      <p>{t('hiring.form.description')}</p>

      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <label htmlFor="name">{t('hiring.form.nameAndSurname')}</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name and surname"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="linkedin">{t('hiring.form.LinkedInProfile')}</label>
          <input
            type="text"
            id="linkedin"
            placeholder="Enter your LinkedIn profile URL"
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}


          />
        </div>

        <div className="form-group">
          <label htmlFor="email">{t('hiring.form.email')}</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="motivation">{t('hiring.form.motivationForThisRole')}</label>
          <textarea
            id="motivation"
            value={motivation}
            onChange={(e) => setMotivation(e.target.value)}
            placeholder="Enter your motivation for this role"
          />
        </div>

        <div className="form-group">
          <label htmlFor="resume" className="attach-label">
            <MdAttachFile className="attach-icon" />
            {t('hiring.form.attachResume')}
            <input type="file" id="resume" onChange={handleFileChange}
                 />
          </label>
        </div>

        <div className="form-group">
          <span className="attachment-text">
            {t('hiring.form.attachments')} ({attachmentCount})
          </span>
        </div>

        

        <div className="recaptcha-text">
          This site is protected by reCAPTCHA and the{' '}
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
            privacy policy
          </a>{' '}
          and{' '}
          <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
            terms of service 
          </a>
          {' '} apply.
        </div>
        <div className="form-group">
          <button   type="submit">Apply Now </button>
        </div>
        
      </form>
    </div>
  );
};

export default ApplyForRoleForm;