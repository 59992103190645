import React, { useState, useEffect } from 'react';
import NavBar from '../../components/navbar/navbar';
import InlineMarkdown from '../../components/inline-markdown/InlineMarkdown';
import BottomBar from '../../components/bottom-bar/bottomBar';
import LineHeader from '../../components/line-header/LineHeader';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Case1TR from '../../assets/mark-downs/cases/tr/case-1_tr.md';
import Case2TR from '../../assets/mark-downs/cases/tr/case-2_tr.md';
import Case3TR from '../../assets/mark-downs/cases/tr/case-3_tr.md';
import Case1EN from '../../assets/mark-downs/cases/en/case-1_en.md';
import Case2EN from '../../assets/mark-downs/cases/en/case-2_en.md';
import Case3EN from '../../assets/mark-downs/cases/en/case-3_en.md';

import './Cases.css';

function Cases() {
    const { t, i18n } = useTranslation();
    var language = i18n.language;
    const caseUrls = language === 'tr' ? [Case1TR, Case2TR, Case3TR] : [Case1EN, Case2EN, Case3EN];
    const caseIds = [1, 2, 3];
    return (
        <div className='Cases'>
            <NavBar />
            <LineHeader className='LineHeader'>{t('landing.header.title')}</LineHeader>
            <div className="CaseContainer">
                {caseIds.map((i) => (
                    <Link className="CaseTile" to={`/Cases/case-${i}`} key={i}>
                        <InlineMarkdown url={caseUrls[i - 1]} charLimit={400} />
                    </Link>
                ))}
            </div>
            <BottomBar />
        </div >
    );
}

export default Cases;