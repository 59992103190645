import React from 'react';
import './dropdown.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Dropdown() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <ul className='dropdown-menu' >
      {currentLanguage === 'tr' && <li classname='nav-item'>
        <Link className="dropdown-link" to="/blog" >
          {t('blog')}
        </Link>
      </li>}
      <li className='nav-item'>
        <Link to='/our-story' className='dropdown-link' dropdown-link>
          {t('ourStory')}
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/our-team' className='dropdown-link' dropdown-link>
          {t('ourTeam')}
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/hiring' className='dropdown-link' dropdown-link>
          {t('internshipOpportunities')}
        </Link>
      </li>
      <li className='nav-item'>
        <Link to='/privacy-security' className='dropdown-link' dropdown-link>
          {t('privacySecurity')}
        </Link>
      </li>
      <li className='nav-item'>
        <span className="dropdown-title">{t('joinNow')}</span>
      </li>
      <li className='nav-item nav-item-horizontal'>
        <Link to='https://app.yeterly.com/' className='dropdown-sub-link' target="_blank" >
          {t('web')}
        </Link>
        <Link to='https://play.google.com/store/apps/details?id=com.yeterly.yeterly' className='dropdown-sub-link' target="_blank" >
          {t('android')}
        </Link>
        <Link to='https://apps.apple.com/tr/app/yeterly/id1667784286' className='dropdown-sub-link' target="_blank" >
          {t('iOS')}
        </Link>
      </li>
    </ul>
  );
}

export default Dropdown;