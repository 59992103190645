import './App.css';
import Landing from './pages/landing/Landing';
import Blog from './pages/blog/Blog';
import FullScreenBlog from './pages/full-screen-blog/FullScreenBlog';

import {
  BrowserRouter as Router,
  Routes,
  Route,
}  from 'react-router-dom';
import Community from './pages/community/community';
import HiringPage from './pages/hiring/hiring-page/HiringPage';
import InternRole from './pages/hiring/intern-roles/InternRole';
import PrivacySecurity from './pages/legal/privacy-security/privacySecurity';
import Etk from './pages/legal/etk/etk';
import Agreement from './pages/legal/agreement/agreement';
import Consent from './pages/legal/consent/consent';
import CommunityStandards from './pages/legal/community-standards/communityStandards';
import Policy from './pages/legal/policy/policy';
import OurTeam from './pages/our-team/ourTeam';
import OurStory from './pages/our-story/ourStory';
import AccessibilityTools from './pages/accessibility-solutions/accessibilitySolutions';
import AccessibilityToolsCategory from './pages/accessibility-solutions/accessibility-solutions-category/accessibilitySolutionsCategory';
import AccessibilityPage from './pages/accessibility-solutions/accessibility-pages/AccessibilityPage';
import Cases from './pages/cases/Cases';
import CasePage from './pages/cases/case-page/CasePage';

const App = () => {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Landing />}></Route>
      <Route path="/our-team" element={<OurTeam />}></Route>
      <Route path="/blog" element={<Blog />}></Route>
      <Route path="/blog/:id" element={<FullScreenBlog />}></Route>
      <Route path="/cases" element={<Cases />}></Route>
      <Route path="/cases/:caseid" element={<CasePage />}></Route>
      <Route path="/hiring" element={<HiringPage />}></Route>
      <Route path="/hiring/:role" element={<InternRole />}></Route>
      <Route path="/find-people-alike-you" element={<Community />}></Route>
      <Route path="/community" element={<Community />}></Route>
      <Route path="/home" element={<Landing />}></Route>
      <Route path="/privacy-security" element={<PrivacySecurity />}></Route>
      <Route path="/etk" element={<Etk />}></Route>
      <Route path="/agreement" element={<Agreement />}></Route>
      <Route path="/consent" element={<Consent />}></Route>
      <Route path="/community-standards" element={<CommunityStandards />}></Route>
      <Route path="/our-story" element={<OurStory />}></Route>
      <Route path="/policy" element={<Policy />}></Route>
      <Route path="/accessibility-solutions" element={<AccessibilityTools />}></Route>
      <Route path="/accessibility-pages/:category" element={<AccessibilityPage />}></Route>
      <Route path="/accessibility-solutions/:category" element={<AccessibilityToolsCategory />}></Route>
      </Routes>

    </Router>
  )}


export default App;
