import React from "react";
import Navbar from "../../components/navbar/navbar";
import "./community.css";
import { useState, useEffect } from "react";
import { collection, getDocs, query, limit, limitToLast, startAfter, orderBy, endBefore } from "firebase/firestore";
import { firestore } from "../../firebase-config";
import BottomBar from "../../components/bottom-bar/bottomBar";
import { useTranslation } from "react-i18next";
import { sendBecomeMemberMail } from "../../api/sendEmail";
import { Link } from "react-router-dom";

export default function Community() {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);

  const [email, setEmail] = useState('');


  const { t } = useTranslation();

  const peoplePerPage = 40;

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendBecomeMemberMail(email);
      alert('Application submitted successfully!');
      setEmail('');

    } catch (error) {
      // Show error message
      alert('An error occurred while submitting the application. Please try again.');
      console.error(error);
    }
  };

  const fetchData = async () => {
    const q = query(collection(firestore, "community"), orderBy("created", "desc"), limit(peoplePerPage));
    const querySnapshot = await getDocs(q);
    const userList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setList(userList);
  };

  const showNext = async () => {
    const lastItem = list[list.length - 1];
    const q = query(collection(firestore, "community"), orderBy("created", "desc"), startAfter(lastItem.created), limit(peoplePerPage));
    const querySnapshot = await getDocs(q);
    const userList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    if (userList.length === 0) {
      return;
    }
    setList(userList);
    setPage(page + 1);
  };

  const showPrevious = async () => {
    const firstItem = list[0];
    const q = query(collection(firestore, "community"), orderBy("created", "desc"), endBefore(firstItem.created), limitToLast(peoplePerPage));
    const querySnapshot = await getDocs(q);
    const userList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setList(userList);
    setPage(page - 1);
  };

  return (
    <div className="Community">
      <Navbar />
      <div className="becomeMemberContainer">
        <h1>{t('becomeMember')}</h1>
        <p>{t('signUpToJoinYeterlyCommunity')}</p>
        <form onSubmit={handleSubmit}>
          <Link className="BecomeMemberButton" to="https://join.yeterly.com/signUp">{t('join')}</Link>
        </form>
      </div>
      <div className="CommunityContainer">
        <h1>{t('yeterlyCommunity')}</h1>
        <div className="PersonList">
          {list.map((person) => (
            <Link key={person.id} className="PersonTile" to={person.link} target="_blank" >
              <img className="PersonPicture" src={person.picture} alt={person.name} />
              <h3>{person.name}</h3>
            </Link>
          ))}
        </div>
        <div className="pagination-container">
          {/* Show previous button only when we are not on the first page */}
          {page !== 1 && <button className="previous-button" onClick={showPrevious}>Previous</button>}
          <span className="page-number">{page}</span>
          {/* Show next button only when we have more items to fetch */}
          {list.length === peoplePerPage && <button className="next-button" onClick={showNext}>Next</button>}
        </div>
      </div>
      <BottomBar />
    </div>
  );
}