import React from 'react';
import './communityStandards.css';
import LineHeader from '../../../components/line-header/LineHeader';
import NavBar from '../../../components/navbar/navbar';
import BottomBar from '../../../components/bottom-bar/bottomBar';
import enContent from './en-content.md';
import trContent from './tr-content.md';
import { useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';

import { useTranslation } from 'react-i18next';
import CustomButton from '../../../components/custom-button/CustomButton';

function CommunityStandards() {
    const { t, i18n } = useTranslation();
    const [mdContent, setMdContent] = useState('');

    useEffect(() => {
        const fetchMarkdownContent = async () => {
            try {
                let response;
                if (i18n.language === 'tr') {
                    response = await fetch(trContent);
                } else {
                    response = await fetch(enContent);
                }
                const md = await response.text();
                setMdContent(md);
            } catch (error) {
                console.error('Error fetching Markdown content:', error);
            }
        };

        fetchMarkdownContent();
    }, [i18n.language]);
    return (
        <>
            <NavBar />
            <LineHeader>{t('communityStandardsTitle')}</LineHeader>
            <div className="consentContent">
                <Markdown>

                    {mdContent}
                </Markdown>

            </div>
            <div className="com-grid-container">
                <div className="com-grid-item">
                    <h1>{t('principlesRightsAndDiginity')}</h1>
                    <img className="com-circle" alt='birçok figürden oluşan önemsiz görsel' src="https://img1.wsimg.com/isteam/ip/7890b365-6a9d-4d1a-a384-18a6952c788c/Pastel%20Minimalist%20Self%20Care%203%20Venn%20Dia-5e79f20.png/:/cr=t:0%25,l:12.5%25,w:75%25,h:100%25/rs=w:600,h:600,cg:true/qt=q:76" />
                    <p className="com-text">{t('principlesRightsAndDiginityDesc')} </p>
                    <CustomButton label={t('humanRights')} url='https://www.un.org/en/about-us/universal-declaration-of-human-rights'/>

                </div>
                <div className="com-grid-item">
                    <h1>{t('principleSafety')}</h1>
                    <img className="com-circle" alt='birçok figürden oluşan önemsiz görsel' src="https://img1.wsimg.com/isteam/ip/7890b365-6a9d-4d1a-a384-18a6952c788c/Pastel%20Minimalist%20Self%20Care%203%20Venn%20Dia-15570c0.png/:/cr=t:0%25,l:12.93%25,w:75%25,h:100%25/rs=w:600,h:600,cg:true/qt=q:76" />

                    <p className="com-text">{t('principleSafetyDesc')} </p>
                </div>
                <div className="com-grid-item">
                <h1>{t('principleAutonomy')}</h1>

                    <img className="com-circle" alt='birçok figürden oluşan önemsiz görsel' src="https://img1.wsimg.com/isteam/ip/7890b365-6a9d-4d1a-a384-18a6952c788c/Pastel%20Minimalist%20Self%20Care%203%20Venn%20Dia-586fbab.png/:/cr=t:0%25,l:0%25,w:75%25,h:100%25/rs=w:600,h:600,cg:true/qt=q:76" />
                    <p className="com-text">{t('principleAutonomyDesc')} </p>
                </div>
                <div className="com-grid-item">
                    <h1>{t('principlePrivacyConfidentiality')}</h1>
                    <img className="com-circle" alt='birçok figürden oluşan önemsiz görsel' src="https://img1.wsimg.com/isteam/ip/7890b365-6a9d-4d1a-a384-18a6952c788c/Pastel%20Minimalist%20Self%20Care%203%20Venn%20Dia-eeb4328.png/:/cr=t:0%25,l:8.26%25,w:75%25,h:100%25/rs=w:600,h:600,cg:true/qt=q:76" />

                    <p className="com-text">{t('principlePrivacyConfidentialityDesc')}</p>
                </div>
            </div>
            <BottomBar />


        </>
    );
}

export default CommunityStandards;