import Axios from "axios";
import { storage } from '../firebase-config';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";


const sendEmailURL = 'https://us-central1-yeterly-website.cloudfunctions.net/sendEmail';


export const sendEmail = async (email, fullname, motivation, linkedin, resume, role) => {

  const storageRef = ref(storage, `resumes/${fullname}/${resume.name}`);
  const uploadTask = uploadBytesResumable(storageRef, resume);


  await uploadTask.on('state_changed',
    (snapshot) => {
      // progress function ...
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      console.log(progress);
    },
    (error) => {
      // Error function ...
      console.log(error);
    },
    () => {
      // get download url and send email
      getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
        const message = `Name: ${fullname} \n\n Email: ${email} \n\n Motivation: ${motivation} \n\n LinkedIn: ${linkedin} \n\n Resume: ${downloadURL}`;

        const name = role + ' Application';
        const subject = 'Yeterly Application';
        const response = await Axios.get(sendEmailURL, {
          params: {
            message,
            name,
            subject
          },
        });

        return response.data;
      }
      );
    });


}


export const sendBecomeMemberMail = async (email) => {

  const message = `${email}`;
  const name = `${email} wants to become a member`;
  const subject = 'Become a member';
  const response = await Axios.get(sendEmailURL, {
    params: {
      message,
      name,
      subject,
    },
  });
  return response.data;
};