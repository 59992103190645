import React from 'react';
import './LineHeader.css'; 
function LineHeader(props) {
  return (
    <div className='LineHeader' >
      <div className='LineHeaderLine' />
      <div className='LineHeaderTitleContainer' >
        <p className='LineHeaderTitle' >
          {props.children}
        </p>
      </div>
      <div className='LineHeaderLine' />
    </div>
  );
}

export default LineHeader;