import React, { useEffect, useState } from 'react';
import './ourStory.css';
import NavBar from '../../components/navbar/navbar';
import BottomBar from '../../components/bottom-bar/bottomBar';
import Action from '../landing/action/Action';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';
import OurStoryTR from './story_tr.md';
import OurStoryEN from './story_en.md';

function OurStory() {
  const { t, i18n } = useTranslation();
  const [story, setStory] = useState("");
  useEffect(() => {
    const fetchBlog = async () => {
      try {
        fetch(i18n.language === 'tr' ? OurStoryTR : OurStoryEN)
          .then((res) => res.text())
          .then((md) => {
            setStory(md);
          });
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };
    fetchBlog();
  }, [i18n.language]);

  return (
    <div className="OurStory">
      <NavBar />
      <div className="OurStoryContainer">
        <div className="OurStoryContent">
          <Markdown className="StoryContent">{story}</Markdown>
          <div className="StoryContent">
            <h1>{t('story.journey')}</h1>
            <div className="VideoContainer">
            <iframe
              className="Video"
              title="video"
              data-ux="Embed"
              allowFullScreen=""
              type="text/html"
              src="//youtube.com/embed/vuDSwzQTU3k?rel=0&amp;showinfo=0&amp;start=0"
              data-aid="VIDEO_VIDEO_RENDERED1"
            />
            </div>
          </div>
        </div>
        <Action />
      </div>
      <BottomBar />
    </div >
  );
}

export default OurStory;