// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAwxvLFxVd-7isbVWAsARJ_ZI2Jf8WjYfY",
  authDomain: "yeterly-website.firebaseapp.com",
  projectId: "yeterly-website",
  storageBucket: "yeterly-website.appspot.com",
  messagingSenderId: "898925361238",
  appId: "1:898925361238:web:e3efe641294b46247823f7",
  measurementId: "G-VL699N3T1Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);