import React from 'react';
import { Link } from 'react-router-dom';
import './privacySecurity.css';
import Navbar from '../../../components/navbar/navbar';
import BottomBar from '../../../components/bottom-bar/bottomBar';
import { useTranslation } from 'react-i18next';

const PrivacySecurity = () => {
    const { t } = useTranslation();

  return (
    <div>
        <Navbar />
    <div className="ps-container">
      <h1 className="ps-header">{t('privacySecurity')}</h1>
      <div className="ps-content">
        <Link to="/etk" className="ps-box">
            {t('etkTitle')}
        </Link>
        <Link to="/consent" className="ps-box">
            {t('consentTitle')}
        </Link>
        <Link to="/policy" className="ps-box">
            {t('policyTitle')}
        </Link>
        <Link to="/agreement" className="ps-box">
        {t('agreementTitle')}
        </Link>
        <Link to="/community-standards" className="ps-box">
           {t('communityStandardsTitle')}
        </Link>
      </div>
    </div>
    <BottomBar />
    </div>
  );
};

export default PrivacySecurity;