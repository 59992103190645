import React from 'react';
import { Link, useParams } from 'react-router-dom';
import './AccessibilityPage.css';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from '../../../firebase-config';
import Navbar from '../../../components/navbar/navbar';
import BottomBar from '../../../components/bottom-bar/bottomBar';
import LineHeader from '../../../components/line-header/LineHeader';


function AccessibilityPage() {
    const [tools, settools] = useState([]);
    const [apps, setApps] = useState([]);
    const { t } = useTranslation();
    const { category } = useParams();

    let type = category.split('-')[0];

    var title = t('solutions.title');

    if (type === 'blindness') {
        title = t('solutions.mainCategory.visual_impairments');
    } else if (type === 'deafness') {
        title = t('solutions.mainCategory.hearing_impairments');
    } else if (type === 'neurodevelopmental') {
        title = t('solutions.mainCategory.neurodevelopmental_disabilities');
    } else if (type === 'mobility') {
        title = t('solutions.mainCategory.mobility_impairments');
    }
    const fetchItems = async (t, kind) => {
        try {
            const querySnapshot = await getDocs(collection(firestore, `${t}-${kind}`));
            const toolList = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            if (kind === 'tools') {
                settools(toolList);
            } else {
                setApps(toolList);
            }
        } catch (error) {
            console.error("Error fetching tools:", error);
        }
    };

    useEffect(() => {
        fetchItems(type, 'tools');
        fetchItems(type, 'apps');
    }, [type]);

    function itemTile(item) {
        return (
            <Link className="tool-app-card" key={item.name} to={item.url} target='_blank' >
                {item.image_url && (
                    <img src={item.image_url} alt={item.name} className="tool-app-image" />
                )}
                <div className="tool-app-details">
                    <div className="tool-app-name">{item.name}</div>
                    <div className="tool-app-type">{item.type}</div>
                    <div className="tool-app-description">
                        {item.description || t('learnMore')}
                    </div>
                </div>
            </Link>
        );
    }
    return (
        <div className='AccessibilityPage'>
            <Navbar />
            <LineHeader>{title}</LineHeader >
                <div className="tools-header">{t('tools')}</div>
                <div className="tool-app-container">
                    {tools.map((tool) => itemTile(tool))}
                </div>
                <hr className="horizontal-divider" />
                <div className="apps-header">{t('apps')}</div>
                <div className="tool-app-container">
                    {apps.map((app) => itemTile(app))}
                </div>
            <BottomBar />
        </div>
    );
}

export default AccessibilityPage;