import './bottomBar.css';
import facebookLogo from "../../assets/images/facebook_logo.png"
import instagramLogo from "../../assets/images/instagram_logo.png"
import linkedlnLogo from "../../assets/images/linkedln_logo.png"
import tiktokLogo from "../../assets/images/tiktok_logo.png"
import twitterLogo from "../../assets/images/twitter_logo.png"
import youtubeLogo from "../../assets/images/youtube_logo.png"

export default function BottomBar() {
    return (
        <div className="bottom-bar">
            <div className="left-content">
                <span>&copy; 2023, Yeterly</span>
            </div>
            <div className="right-content">
                <a href="https://www.facebook.com/people/Yeterly/100083556334797/" target='_blank' rel="noopener noreferrer">
                    <img src={facebookLogo} alt='Facebook Logo'/>
                </a>
                <a href="https://www.instagram.com/yeterly_official/" target='_blank' rel="noopener noreferrer">
                    <img src={instagramLogo} alt='Instagram Logo'/>
                </a>
                <a href="https://www.linkedin.com/company/yeterlyofficial/" target='_blank' rel="noopener noreferrer">
                    <img src={linkedlnLogo} alt='LinkedIn Logo'/>
                </a>
                <a href="https://www.tiktok.com/@yeterly_official" target='_blank' rel="noopener noreferrer">
                    <img src={tiktokLogo} alt='TikTok Logo'/>
                </a>
                <a href="https://twitter.com/yeterly_app" target='_blank' rel="noopener noreferrer">
                    <img src={twitterLogo} alt='Twitter Logo'/>
                </a>
                <a href="https://www.youtube.com/channel/UCrstjUD4Cb7IxMGtNhFvBuw" target='_blank' rel="noopener noreferrer">
                    <img src={youtubeLogo} alt='Youtube Logo'/>
                </a>
            </div>
        </div>
    );
}
