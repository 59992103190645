import React from 'react';
import './blog.css';
import { useTranslation } from 'react-i18next';
import NavBar from '../../components/navbar/navbar';
import { Link } from 'react-router-dom';
import BlogPost from '../../components/blog-post/blogPost';
import LandingVideo from '../../components/landing-video/landingVideo';
import {firestore} from '../../firebase-config';
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";



function Blog() {
    const { t, i18n } = useTranslation();

    const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        await getDocs(collection(firestore, "blogs")).then((querySnapshot) => {
          const blogList = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          const sortedBlogs = blogList.sort((a, b) => new Date(b.created_at.toDate()) - new Date(a.created_at.toDate()));

          setBlogs(sortedBlogs);
        });
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  if (blogs.length === 0) {

    return   <div className="blogPage">
    <NavBar />
    <LandingVideo /><div>No Post...</div>
    </div>
  }
  return (
    <div className="blogPage">
    <NavBar />
    <div className="blogListContainer">
      {blogs.map((blog) => (
        <div key={blog.id} className="blogItemContainer">
          <Link to={`/blog/${blog.id}`}>
            <BlogPost
              title={blog.title}
              date={blog.created_at}
              thumbnail={blog.thumbnail}
            />
          </Link>
        </div>
      ))}
    </div>
  </div>
  );
}

export default Blog;