import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { doc, getDoc } from "firebase/firestore";
import { firestore } from '../../firebase-config';
import Navbar from '../../components/navbar/navbar';
import BottomBar from '../../components/bottom-bar/bottomBar';
import LineHeader from '../../components/line-header/LineHeader';
import './accessibilitySolutions.css';

import visionImpairmentsImage from '../../assets/images/blidness_and_vision_impairments.png'
import hearingImpairmentsImage from '../../assets/images/deafness_and_hearing_loss.png'
import neurodevelopmentalDisabilitiesImage from '../../assets/images/neurodevelopmental_disabilities.png'
import speechImpairmentsImage from '../../assets/images/speech_impairments.png'
import mobilityImpairmentsImage from '../../assets/images/mobility_impairments.png'

function AccessibilityTools() {
    const [mainCategories, setMainCategories] = useState([]);
    const { t } = useTranslation();
    const images = [mobilityImpairmentsImage,neurodevelopmentalDisabilitiesImage,speechImpairmentsImage,hearingImpairmentsImage,visionImpairmentsImage,speechImpairmentsImage]

    useEffect(() => {
        const fetchtools = async () => {
            try {
                const docSnapshot = await getDoc(doc(firestore, "solutions/main_category_list"));
                const mCategories = docSnapshot.data().main_category_list;
                setMainCategories(mCategories);
            } catch (error) {
                console.error("Error fetching tools:", error);
            }
        };

        fetchtools();
    }, []);
    return (
        <div className='AccessibilityTools'>
            <Navbar />
            <LineHeader>{t('solutions.title')}</LineHeader>
            <div className="category-list">{mainCategories.map((c,index) => (
                <Link key={c} className="CategoryLink" to={`/accessibility-solutions/${c}`} >
                    <img className="CategoryImage" alt={t('imageAltForManyFigures')} src={images[index]} />
                    <div className="CategoryTitleContainer">
                        <h3 className="CategoryTitle">{t(`solutions.mainCategory.${c}`)}</h3>
                    </div>
                </Link>
            ))}
            </div>
            <BottomBar />
        </div>
    );
}

export default AccessibilityTools;