import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from '../../../firebase-config';
import { Link } from 'react-router-dom';

import './latestArticles.css';

function LatestArticles() {
  const [blogs, setBlogs] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, "blogs"));
        const blogList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBlogs(blogList.slice(0, 3)); // Update the state with the latest 3 blogs
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div className='LatestArticles'>
      <h2 className='LatestArticlesTitle'>{t('discoverLatestArticles')}</h2>
      <div className="ArticleList">
        {blogs.map((blog) => (
          <Link to={`/blog/${blog.id}`} className="ArticleLink" key={blog.id}>
            <div className="Article">
              <img src={blog.thumbnail} alt={blog.title} />
              <h3>{blog.title}</h3>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default LatestArticles;