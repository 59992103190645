import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import NavBar from '../../../components/navbar/navbar';
import InlineMarkdown from '../../../components/inline-markdown/InlineMarkdown';
import BottomBar from '../../../components/bottom-bar/bottomBar';

import { useTranslation } from 'react-i18next';
import Case1TR from '../../../assets/mark-downs/cases/tr/case-1_tr.md';
import Case2TR from '../../../assets/mark-downs/cases/tr/case-2_tr.md';
import Case3TR from '../../../assets/mark-downs/cases/tr/case-3_tr.md';
import Case1EN from '../../../assets/mark-downs/cases/en/case-1_en.md';
import Case2EN from '../../../assets/mark-downs/cases/en/case-2_en.md';
import Case3EN from '../../../assets/mark-downs/cases/en/case-3_en.md';

import './CasePage.css';

function CasePage() {
    const { i18n } = useTranslation();
    const { caseid } = useParams();

    const language = i18n.language;
    const cases = language === 'tr' ? [Case1TR, Case2TR, Case3TR] : [Case1EN, Case2EN, Case3EN];
    const caseFile = cases[parseInt(caseid.slice(-1)) - 1];

    return (
        <div className='CasePage'>
            <NavBar />
            <div className='CasePageContainer'>
                <InlineMarkdown url={caseFile} />
            </div>
            <BottomBar />
        </div >
    );
}

export default CasePage;