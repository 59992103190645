import './blogPost.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const BlogPost = ({ title, date, thumbnail }) => {
  // Convert Firebase timestamp to plain text
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date?.seconds * 1000).toLocaleDateString(undefined, options);
  };

  return (
    <div className="card">
      <img src={thumbnail} alt="Blog post" className="card-image" />
      <div className="card-content">
        <p className="card-title">{title}</p>
        <p className="card-date">{formatDate(date)}</p>
      </div>
    </div>
  );
};

export default BlogPost;